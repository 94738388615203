import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Container } from "../blocks";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Container;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About`}</h1>
    <p><img parentName="p" {...{
        "src": "/brent-jackson.jpg",
        "alt": null
      }}></img></p>
    <p>{`Vitaly Yastremsky`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      